import React, { useEffect, useState } from "react";
import { StoreInterface } from "../../assets/interfaces";
import { getWhatsappLink } from "../../assets/js/utils/utils";
import { useModals } from "../hooks/useModals";
import Portal from "../portal";
import { AppBtn } from "../ui/buttons";
import ContentWithCopy from "../ui/content-with-copy";
import ImageWithPlaceholder from "../ui/image-with-placeholder";
import Modal, { ModalBody } from "../ui/modal";
import ZillaModal from "./modals/zilla-modal";
import { ProductImages } from "../products/modals/product-details";
import ImageCarouselModal from "../products/image-carousel-modal";
import { useRouter } from "next/router";

interface Props {
  store: StoreInterface;
  customerName?: string;
}

const StoreFrontDetails: React.FC<Props> = ({ store, customerName }) => {
  // const { width } = useScreenSize();
  // const isSmall = width < 800;
  const { toggleModal, modals } = useModals(["details", "zilla_modal"]);

  return (
    <section className="store-details flex items-start">
      {!store?.has_paid_subscription && (
        <ImageWithPlaceholder
          image={store?.logo}
          text={store.name}
          className="h-10 w-10 sm:h-15 sm:w-15 text-xl sm:text-2xl font-bold flex-shrink-0 mr-2.5 sm:mr-5 "
        />
      )}
      <div className="flex-1 max-w-full overflow-hidden sm:max-w-[850px]">
        <h2 className="text-black font-bold text-lg sm:text-xl md:text-2lg inline-flex items-center">
          {store.name}
          {/* {store?.payments_enabled && store?.payment_options.find((o) => o.type === "ZILLA")?.enabled && (
            <button
              className="h-5 w-5 md:h-5.5 md:w-5.5 rounded-full bg-black ml-1 mt-0.5 overflow-hidden"
              onClick={() => toggleModal("zilla_modal")}
            >
              <img
                src="https://usezilla.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fc6104b06-ff79-4177-abd1-ec23b61bf6a8%2Fnotion_zilla_icon.png?table=block&id=fd834fd9-1648-458f-b5bb-f68acce27242&spaceId=4a9bb03d-9104-4e65-9a9a-9c07ab5cd487&width=250&userId=&cache=v2"
                alt="Zilla Black Logo"
                className="h-full w-full"
              />
            </button>
          )} */}
        </h2>
        <p className="text-dark text-sm sm:text-1sm md:mt-1">
          {store.description}
        </p>

        <div className="flex items-center mt-5">
          <AppBtn
            size="sm"
            className="mr-2.5"
            href={getWhatsappLink(
              store?.phone,
              store.configuration.enquiry_message ?? `Hi, i'll like to make an enquiry about ${store.name}`
            )}
          >
            {/* prettier-ignore */}
            <svg className="w-3.5 sm:w-4 lg:w-4.5 flex-shrink-0 mr-1.25" width="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.9 20.6C8.4 21.5 10.2 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 13.8 2.5 15.5 3.3 17L2.44044 20.306C2.24572 21.0549 2.93892 21.7317 3.68299 21.5191L6.9 20.6Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.5 14.8485C16.5 15.0105 16.4639 15.177 16.3873 15.339C16.3107 15.501 16.2116 15.654 16.0809 15.798C15.86 16.041 15.6167 16.2165 15.3418 16.329C15.0714 16.4415 14.7784 16.5 14.4629 16.5C14.0033 16.5 13.512 16.392 12.9937 16.1715C12.4755 15.951 11.9572 15.654 11.4434 15.2805C10.9251 14.9025 10.4339 14.484 9.9652 14.0205C9.501 13.5525 9.08187 13.062 8.70781 12.549C8.33826 12.036 8.04081 11.523 7.82449 11.0145C7.60816 10.5015 7.5 10.011 7.5 9.543C7.5 9.237 7.55408 8.9445 7.66224 8.6745C7.77041 8.4 7.94166 8.148 8.18052 7.923C8.46895 7.6395 8.78443 7.5 9.11793 7.5C9.24412 7.5 9.37031 7.527 9.48297 7.581C9.60015 7.635 9.70381 7.716 9.78493 7.833L10.8305 9.3045C10.9116 9.417 10.9702 9.5205 11.0108 9.6195C11.0513 9.714 11.0739 9.8085 11.0739 9.894C11.0739 10.002 11.0423 10.11 10.9792 10.2135C10.9206 10.317 10.835 10.425 10.7268 10.533L10.3843 10.8885C10.3348 10.938 10.3122 10.9965 10.3122 11.0685C10.3122 11.1045 10.3167 11.136 10.3257 11.172C10.3393 11.208 10.3528 11.235 10.3618 11.262C10.4429 11.4105 10.5826 11.604 10.7809 11.838C10.9837 12.072 11.2 12.3105 11.4344 12.549C11.6778 12.7875 11.9121 13.008 12.151 13.2105C12.3853 13.4085 12.5791 13.5435 12.7323 13.6245C12.7549 13.6335 12.7819 13.647 12.8135 13.6605C12.8495 13.674 12.8856 13.6785 12.9261 13.6785C13.0028 13.6785 13.0613 13.6515 13.1109 13.602L13.4534 13.2645C13.5661 13.152 13.6743 13.0665 13.7779 13.0125C13.8816 12.9495 13.9852 12.918 14.0979 12.918C14.1835 12.918 14.2737 12.936 14.3728 12.9765C14.472 13.017 14.5756 13.0755 14.6883 13.152L16.18 14.2095C16.2972 14.2905 16.3783 14.385 16.4279 14.4975C16.473 14.61 16.5 14.7225 16.5 14.8485Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"/>
            </svg>
            Chat with us
          </AppBtn>
          <AppBtn size="sm" color="neutral" onClick={() => toggleModal("details")}>
            More Info
            {/* prettier-ignore */}
            <svg className="w-3 sm:w-3.75 lg:w-4 ml-1.25" viewBox="0 0 15 15" fill="none">
              <path d="M3.53589 11.0359L10.607 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.6062 11.0359L10.6062 3.96482L3.53513 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </AppBtn>
        </div>

      </div>
      <Portal>
        <StoreDetailsModal show={modals.details.show} toggle={(state) => toggleModal("details")} store={store} />
        <ZillaModal
          show={modals?.zilla_modal?.show}
          toggle={() => toggleModal("zilla_modal")}
          storeName={store?.name}
        />
      </Portal>
    </section>
  );
};

export default StoreFrontDetails;

interface StoreDetailsProps {
  store: StoreInterface;
  toggle: (state: boolean) => void;
  show: boolean;
}

export const StoreDetailsModal: React.FC<StoreDetailsProps> = ({ store, toggle, show }) => {
  const [storeLink, setStoreLink] = useState("");
  const socials = Object.entries(store?.socials)
    .filter((e) => e?.[1])
    .map((e) => ({ type: e[0], handle: e[1] }));
  const { modals, toggleModal } = useModals(["image_carousel"]);

  useEffect(() => {
    setStoreLink(window.location.href);
  }, []);

  return (
    <Modal title="More Information" {...{ toggle, show }}>
      <ModalBody>
        <div className="flex justify-between items-center pb-3.75">
          <div className="">
            <h3 className="font-bold text-base sm:text-[17px] text-black-secondary">Our Location:</h3>
            <span className="text-dark text-sm">
              {store?.address ? `${store.address},` : ""} {store?.state ? `${store?.state},` : ""}{" "}
              {store?.country?.name}
            </span>
          </div>
          <div className="bg-accent-yellow-500 w-11 h-11 flex-shrink-0 flex items-center justify-center p-2.5 rounded-full">
            {/* prettier-ignore */}
            <svg className="w-full" viewBox="0 0 24 24" fill="none" >
              <path d="M20.6201 8.45C19.5701 3.83 15.5401 1.75 12.0001 1.75C12.0001 1.75 12.0001 1.75 11.9901 1.75C8.4601 1.75 4.4201 3.82 3.3701 8.44C2.2001 13.6 5.3601 17.97 8.2201 20.72C9.2801 21.74 10.6401 22.25 12.0001 22.25C13.3601 22.25 14.7201 21.74 15.7701 20.72C18.6301 17.97 21.7901 13.61 20.6201 8.45ZM12.0001 13.46C10.2601 13.46 8.8501 12.05 8.8501 10.31C8.8501 8.57 10.2601 7.16 12.0001 7.16C13.7401 7.16 15.1501 8.57 15.1501 10.31C15.1501 12.05 13.7401 13.46 12.0001 13.46Z" fill="white"/>
            </svg>
          </div>
        </div>

        {store?.delivery_locations && (
          <div className="flex justify-between items-center py-3.75 border-t border-grey-divider ">
            <div className="">
              <h3 className="font-bold text-base sm:text-[17px] text-black-secondary">We Deliver To:</h3>
              <span className="text-dark text-sm">{store.delivery_locations}</span>
            </div>
            <div className="bg-accent-yellow-500 text-white w-11 h-11 flex-shrink-0 flex items-center justify-center p-2.5 rounded-full">
              {/* prettier-ignore */}
              <svg className="w-full" viewBox="0 0 18 18" fill="none">
                <path d="M15.1577 5.865L9.38266 9.21C9.15016 9.345 8.85766 9.345 8.61766 9.21L2.84266 5.865C2.43016 5.625 2.32516 5.0625 2.64016 4.71C2.85766 4.4625 3.10516 4.26 3.36766 4.1175L7.43266 1.8675C8.30266 1.38 9.71266 1.38 10.5827 1.8675L14.6477 4.1175C14.9102 4.26 15.1577 4.47 15.3752 4.71C15.6752 5.0625 15.5702 5.625 15.1577 5.865Z" fill="currentColor"/>
                <path d="M8.57236 10.605V15.72C8.57236 16.29 7.99486 16.665 7.48486 16.4175C5.93986 15.66 3.33736 14.2425 3.33736 14.2425C2.42236 13.725 1.67236 12.42 1.67236 11.3475V7.4775C1.67236 6.885 2.29486 6.51 2.80486 6.8025L8.19736 9.93C8.42236 10.0725 8.57236 10.3275 8.57236 10.605Z" fill="currentColor"/>
                <path d="M9.42773 10.605V15.72C9.42773 16.29 10.0052 16.665 10.5152 16.4175C12.0602 15.66 14.6627 14.2425 14.6627 14.2425C15.5777 13.725 16.3277 12.42 16.3277 11.3475V7.4775C16.3277 6.885 15.7052 6.51 15.1952 6.8025L9.80273 9.93C9.57773 10.0725 9.42773 10.3275 9.42773 10.605Z" fill="currentColor"/>
              </svg>
            </div>
          </div>
        )}

        {store?.extra_info?.images?.length > 0 && (
          <div className="flex justify-between items-center py-3.75 border-t border-grey-divider">
            <div className="">
              <h3 className="font-bold text-base sm:text-[17px] text-black-secondary">
                {" "}
                {store.extra_info?.images_label}{" "}
              </h3>
              <button
                onClick={() => toggleModal("image_carousel")}
                className=" text-sm text-primary-500 font-medium flex items-center"
              >
                View {store.extra_info?.images_label}
                {/* prettier-ignore */}
                <svg className="w-3 sm:w-3.75 lg:w-4 ml-1.25" viewBox="0 0 15 15" fill="none"><path d="M3.53589 11.0359L10.607 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.6062 11.0359L10.6062 3.96482L3.53513 3.96482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              </button>
            </div>
            <div className="bg-accent-yellow-500 text-white w-11 h-11 flex-shrink-0 flex items-center justify-center p-2.5 rounded-full">
              {/* prettier-ignore */}
              <svg className="w-full" viewBox="0 0 24 24" fill="none">
              <path d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z" fill="white"/>
              <path d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z" fill="white"/>
              <path d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z" fill="white"/>
              <path d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z" fill="white"/>
              </svg>
            </div>
          </div>
        )}

        {/* {store.extra_info?.production_timeline && (
          <div className="flex justify-between items-center py-3.75 border-b border-grey-divider">
            <div className="">
              <h3 className="font-bold text-base sm:text-[17px] text-black-secondary">Production Timeline</h3>
              <span className="text-dark text-sm">{store.extra_info.production_timeline}</span>
            </div>
            <div className="bg-accent-yellow-500 text-white w-11 h-11 flex-shrink-0 flex items-center justify-center p-2.5 rounded-full">
              prettier-ignore
              <svg className="w-full" viewBox="0 0 24 24" fill="none">
              <path d="M11.9975 19.75H9.3175C8.1575 19.75 7.1475 19.05 6.7475 17.97C6.3375 16.89 6.6375 15.7 7.5075 14.93L15.4975 7.94C15.9775 7.52 15.9875 6.95 15.8475 6.56C15.6975 6.17 15.3175 5.75 14.6775 5.75H11.9975C11.5875 5.75 11.2475 5.41 11.2475 5C11.2475 4.59 11.5875 4.25 11.9975 4.25H14.6775C15.8375 4.25 16.8475 4.95 17.2475 6.03C17.6575 7.11 17.3575 8.3 16.4875 9.07L8.4975 16.06C8.0175 16.48 8.0075 17.05 8.1475 17.44C8.2975 17.83 8.6775 18.25 9.3175 18.25H11.9975C12.4075 18.25 12.7475 18.59 12.7475 19C12.7475 19.41 12.4075 19.75 11.9975 19.75Z" fill="white"/>
              <path d="M20 15H17C15.9 15 15 15.9 15 17V20C15 21.1 15.9 22 17 22H20C21.1 22 22 21.1 22 20V17C22 15.9 21.1 15 20 15ZM18.51 19.5C17.96 19.5 17.51 19.05 17.51 18.5C17.51 17.95 17.95 17.5 18.51 17.5H18.52C19.07 17.5 19.52 17.95 19.52 18.5C19.52 19.05 19.07 19.5 18.51 19.5Z" fill="white"/>
              <path d="M5.46875 2C3.53875 2 1.96875 3.57 1.96875 5.5C1.96875 7.43 3.53875 9 5.46875 9C7.39875 9 8.96875 7.43 8.96875 5.5C8.96875 3.57 7.40875 2 5.46875 2ZM5.50875 6.5C4.95875 6.5 4.50875 6.05 4.50875 5.5C4.50875 4.95 4.94875 4.5 5.50875 4.5H5.51875C6.06875 4.5 6.51875 4.95 6.51875 5.5C6.51875 6.05 6.06875 6.5 5.50875 6.5Z" fill="white"/>
              </svg>
            </div>
          </div>
        )} */}
        {store?.extra_info?.delivery_timeline && (
          <div className="flex justify-between items-center py-3.75 border-t border-grey-divider">
            <div className="">
              <h3 className="font-bold text-base sm:text-[17px] text-black-secondary">Average Delivery Timeline</h3>
              <span className="text-dark text-sm">{store.extra_info.delivery_timeline}</span>
            </div>
            <div className="bg-accent-yellow-500 text-white w-11 h-11 flex-shrink-0 flex items-center justify-center p-2.5 rounded-full">
              {/* prettier-ignore */}
              <svg className="w-full" viewBox="0 0 24 24" fill="none">
              <path d="M17.6005 5.31125L11.9505 2.27125C11.3505 1.95125 10.6405 1.95125 10.0405 2.27125L4.40047 5.31125C3.99047 5.54125 3.73047 5.98125 3.73047 6.46125C3.73047 6.95125 3.98047 7.39125 4.40047 7.61125L10.0505 10.6512C10.3505 10.8112 10.6805 10.8913 11.0005 10.8913C11.3205 10.8913 11.6605 10.8112 11.9505 10.6512L17.6005 7.61125C18.0105 7.39125 18.2705 6.95125 18.2705 6.46125C18.2705 5.98125 18.0105 5.54125 17.6005 5.31125Z" fill="white"/>
              <path d="M9.12 11.7106L3.87 9.09058C3.46 8.88058 3 8.91058 2.61 9.14058C2.23 9.38058 2 9.79058 2 10.2406V15.2006C2 16.0606 2.48 16.8306 3.25 17.2206L8.5 19.8406C8.68 19.9306 8.88 19.9806 9.08 19.9806C9.31 19.9806 9.55 19.9106 9.76 19.7906C10.14 19.5506 10.37 19.1406 10.37 18.6906V13.7306C10.36 12.8706 9.88 12.1006 9.12 11.7106Z" fill="white"/>
              <path d="M20.0006 10.2406V12.7006C19.5206 12.5606 19.0106 12.5006 18.5006 12.5006C17.1406 12.5006 15.8106 12.9706 14.7606 13.8106C13.3206 14.9406 12.5006 16.6506 12.5006 18.5006C12.5006 18.9906 12.5606 19.4806 12.6906 19.9506C12.5406 19.9306 12.3906 19.8706 12.2506 19.7806C11.8706 19.5506 11.6406 19.1406 11.6406 18.6906V13.7306C11.6406 12.8706 12.1206 12.1006 12.8806 11.7106L18.1306 9.09058C18.5406 8.88058 19.0006 8.91058 19.3906 9.14058C19.7706 9.38058 20.0006 9.79058 20.0006 10.2406Z" fill="white"/>
              <path d="M21.98 15.65C21.16 14.64 19.91 14 18.5 14C17.44 14 16.46 14.37 15.69 14.99C14.65 15.81 14 17.08 14 18.5C14 19.91 14.64 21.16 15.65 21.98C16.42 22.62 17.42 23 18.5 23C19.64 23 20.67 22.57 21.47 21.88C22.4 21.05 23 19.85 23 18.5C23 17.42 22.62 16.42 21.98 15.65ZM19.53 18.78C19.53 19.04 19.39 19.29 19.17 19.42L17.76 20.26C17.64 20.33 17.51 20.37 17.37 20.37C17.12 20.37 16.87 20.24 16.73 20.01C16.52 19.65 16.63 19.19 16.99 18.98L18.03 18.36V17.1C18.03 16.69 18.37 16.35 18.78 16.35C19.19 16.35 19.53 16.69 19.53 17.1V18.78Z" fill="white"/>
              </svg>
            </div>
          </div>
        )}
        {/* {store.extra_info?.refund_policy && (
          <div className="flex justify-between items-center py-3.75 border-b border-grey-divider">
            <div className="">
              <h3 className="font-bold text-base sm:text-lg">Refund Policy</h3>
              <span className="text-dark text-sm">{store.extra_info.refund_policy}</span>
            </div>
            <div className="bg-accent-red-500 text-white w-11 h-11 flex-shrink-0 flex items-center justify-center p-2.5 rounded-full">
              prettier-ignore
              <svg className="w-full" viewBox="0 0 24 24" fill="none">
              <path d="M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z" fill="white"/>
              <path d="M17.2391 4.81949C17.2391 6.40949 15.9391 7.70949 14.3491 7.70949H9.64906C8.05906 7.70949 6.75906 6.40949 6.75906 4.81949C6.75906 4.25949 6.15906 3.90949 5.65906 4.16949C4.24906 4.91949 3.28906 6.40949 3.28906 8.11949V17.5295C3.28906 19.9895 5.29906 21.9995 7.75906 21.9995H16.2391C18.6991 21.9995 20.7091 19.9895 20.7091 17.5295V8.11949C20.7091 6.40949 19.7491 4.91949 18.3391 4.16949C17.8391 3.90949 17.2391 4.25949 17.2391 4.81949ZM15.5291 17.5295C15.3791 17.6795 15.1891 17.7495 14.9991 17.7495C14.8091 17.7495 14.6191 17.6795 14.4691 17.5295L9.74906 12.8095V13.9995C9.74906 14.4095 9.40906 14.7495 8.99906 14.7495C8.58906 14.7495 8.24906 14.4095 8.24906 13.9995V10.9995C8.24906 10.5895 8.58906 10.2495 8.99906 10.2495H11.9991C12.4091 10.2495 12.7491 10.5895 12.7491 10.9995C12.7491 11.4095 12.4091 11.7495 11.9991 11.7495H10.8091L15.5291 16.4695C15.8191 16.7595 15.8191 17.2395 15.5291 17.5295Z" fill="white"/>
              </svg>
            </div>
          </div>
        )} */}

        {/* <div className="flex justify-between items-center py-3.75">
          <div className="">
            <h3 className="font-bold text-base sm:text-[17px] text-black-secondary">Store Link</h3>
            <ContentWithCopy className="" text={storeLink}>
              <span className="text-xs sm:text-1xs text-dark font-medium">{storeLink}</span>
            </ContentWithCopy>
          </div>
          <div className="bg-accent-yellow-500 w-11 h-11 ml-2 flex-shrink-0 flex items-center justify-center p-2.5 rounded-full">
            prettier-ignore
            <svg className="w-full" viewBox="0 0 24 24" fill="none" >
              <path d="M22.3601 8.77L22.0701 6C21.6501 2.98 20.2801 1.75 17.3501 1.75H14.9901H13.5101H10.4701H8.99014H6.59014C3.65014 1.75 2.29014 2.98 1.86014 6.03L1.59014 8.78C1.49014 9.85 1.78014 10.89 2.41014 11.7C3.17014 12.69 4.34014 13.25 5.64014 13.25C6.90014 13.25 8.11014 12.62 8.87014 11.61C9.55014 12.62 10.7101 13.25 12.0001 13.25C13.2901 13.25 14.4201 12.65 15.1101 11.65C15.8801 12.64 17.0701 13.25 18.3101 13.25C19.6401 13.25 20.8401 12.66 21.5901 11.62C22.1901 10.82 22.4601 9.81 22.3601 8.77Z" fill="white"/>
              <path d="M11.3501 17.16C10.0801 17.29 9.12012 18.37 9.12012 19.65V22.39C9.12012 22.66 9.34012 22.88 9.61012 22.88H14.3801C14.6501 22.88 14.8701 22.66 14.8701 22.39V20C14.8801 17.91 13.6501 16.92 11.3501 17.16Z" fill="white"/>
              <path d="M21.3699 14.9V17.88C21.3699 20.64 19.1299 22.88 16.3699 22.88C16.0999 22.88 15.8799 22.66 15.8799 22.39V20C15.8799 18.72 15.4899 17.72 14.7299 17.04C14.0599 16.43 13.1499 16.13 12.0199 16.13C11.7699 16.13 11.5199 16.14 11.2499 16.17C9.46988 16.35 8.11988 17.85 8.11988 19.65V22.39C8.11988 22.66 7.89988 22.88 7.62988 22.88C4.86988 22.88 2.62988 20.64 2.62988 17.88V14.92C2.62988 14.22 3.31988 13.75 3.96988 13.98C4.23988 14.07 4.50988 14.14 4.78988 14.18C4.90988 14.2 5.03988 14.22 5.15988 14.22C5.31988 14.24 5.47988 14.25 5.63988 14.25C6.79988 14.25 7.93988 13.82 8.83988 13.08C9.69988 13.82 10.8199 14.25 11.9999 14.25C13.1899 14.25 14.2899 13.84 15.1499 13.1C16.0499 13.83 17.1699 14.25 18.3099 14.25C18.4899 14.25 18.6699 14.24 18.8399 14.22C18.9599 14.21 19.0699 14.2 19.1799 14.18C19.4899 14.14 19.7699 14.05 20.0499 13.96C20.6999 13.74 21.3699 14.22 21.3699 14.9Z" fill="white"/>
            </svg>
          </div>
        </div> */}

        {/* {socials.length > 0 && (
          <div className="pt-4 border-t border-grey-border border-opacity-50">
            <h3 className="font-bold text-base sm:text-[17px] text-black-secondary">Our Social Media</h3>
            <ul className="mt-2.5 space-y-4">
              {socials.map((s, index) => (
                <li className="flex items-center justify-between" key={index}>
                  <div className="flex items-center">
                    <figure
                      className={`h-6.25 w-6.25 sm:h-6.75 sm:w-6.75 rounded-full flex items-center p-1.5 ${
                        socialsDataMap[s.type].color
                      }`}
                    >
                      {socialsDataMap[s.type].icon}
                    </figure>
                    <span className="inline-block ml-2 text-dark text-1xs sm:text-sm capitalize">{s.type}</span>
                  </div>
                  <a
                    href={`${socialsDataMap[s.type].prefix}/${s.handle}`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center text-primary-500 font-semibold text-1xs sm:text-sm"
                  >
                    <span>@{s.handle}</span>
                    prettier-ignore
                    <svg className="w-2 sm:w-2.5 mt-0.5 ml-1" viewBox="0 0 10 10" fill="none">
                      <path d="M1.24264 0.24265V1.73818L7.16643 1.74348L0.71231 8.1976L1.77297 9.25826L8.22709 2.80414L8.23239 8.72793H9.72792V0.24265H1.24264Z" fill="currentColor"/>
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )} */}
        <Portal>
          <ImageCarouselModal
            show={modals.image_carousel.show}
            toggle={() => toggleModal("image_carousel")}
            images={store?.extra_info?.images ?? []}
            title={`${store?.extra_info?.images_label} Images`}
          />
        </Portal>
      </ModalBody>
    </Modal>
  );
};

export const socialsDataMap = {
  instagram: {
    icon:
      // prettier-ignore
      <svg width="100%" viewBox="0 0 15 15" fill="none">
        <g clip-path="url(#clip0_7717_71933)">
          <path d="M10.625 1.25H4.375C2.64911 1.25 1.25 2.64911 1.25 4.375V10.625C1.25 12.3509 2.64911 13.75 4.375 13.75H10.625C12.3509 13.75 13.75 12.3509 13.75 10.625V4.375C13.75 2.64911 12.3509 1.25 10.625 1.25Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.99982 7.10619C10.0769 7.62635 9.9881 8.15758 9.74592 8.62433C9.50373 9.09108 9.12053 9.46958 8.65083 9.70599C8.18113 9.9424 7.64885 10.0247 7.12968 9.94115C6.61052 9.85761 6.13092 9.6125 5.75909 9.24067C5.38727 8.86884 5.14215 8.38924 5.05861 7.87008C4.97507 7.35091 5.05736 6.81863 5.29377 6.34893C5.53018 5.87923 5.90868 5.49603 6.37543 5.25385C6.84218 5.01166 7.37341 4.92281 7.89357 4.99994C8.42414 5.07862 8.91535 5.32586 9.29463 5.70514C9.6739 6.08441 9.92114 6.57562 9.99982 7.10619Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.9375 4.0625H10.9438" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_7717_71933">
            <rect width="100%" height="100%" fill="white"/>
          </clipPath>
        </defs>
      </svg>,
    color: "text-accent-red-500 bg-grey-fields-100",
    prefix: "https://instagram.com",
  },
  twitter: {
    icon:
      // prettier-ignore
      <svg width="100%" viewBox="0 0 15 15" fill="none">
        <path d="M14.375 1.87506C13.7765 2.29723 13.1138 2.62013 12.4125 2.83131C12.0361 2.3985 11.5358 2.09174 10.9794 1.95251C10.423 1.81328 9.83719 1.84831 9.3013 2.05284C8.76542 2.25738 8.30528 2.62156 7.98311 3.09613C7.66095 3.5707 7.49231 4.13277 7.5 4.70631V5.33131C6.40165 5.35979 5.3133 5.11619 4.33188 4.62221C3.35046 4.12823 2.50645 3.39921 1.875 2.50006C1.875 2.50006 -0.625 8.12506 5 10.6251C3.71283 11.4988 2.17947 11.9369 0.625 11.8751C6.25 15.0001 13.125 11.8751 13.125 4.68756C13.1244 4.51347 13.1077 4.33981 13.075 4.16881C13.7129 3.53974 14.163 2.7455 14.375 1.87506Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,
    color: "text-accent-orange-500 bg-grey-fields-100",
    prefix: "https://twitter.com",
  },
  facebook: {
    icon:
      // prettier-ignore
      <svg width="100%" viewBox="0 0 15 15" fill="none">
        <path d="M8.75 5.8125V7.625H10.375C10.5 7.625 10.5625 7.75 10.5625 7.875L10.3125 9.0625C10.3125 9.125 10.1875 9.1875 10.125 9.1875H8.75V13.75H6.875V9.25H5.8125C5.6875 9.25 5.625 9.1875 5.625 9.0625V7.875C5.625 7.75 5.6875 7.6875 5.8125 7.6875H6.875V5.625C6.875 4.5625 7.6875 3.75 8.75 3.75H10.4375C10.5625 3.75 10.625 3.8125 10.625 3.9375V5.4375C10.625 5.5625 10.5625 5.625 10.4375 5.625H8.9375C8.8125 5.625 8.75 5.6875 8.75 5.8125Z" stroke="currentColor" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M9.375 13.75H5.625C2.5 13.75 1.25 12.5 1.25 9.375V5.625C1.25 2.5 2.5 1.25 5.625 1.25H9.375C12.5 1.25 13.75 2.5 13.75 5.625V9.375C13.75 12.5 12.5 13.75 9.375 13.75Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,
    color: "text-primary-500 bg-grey-fields-100",
    prefix: "https://facebook.com",
  },
  snapchat: {
    icon:
      // prettier-ignore
      <svg width="100%"  viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2999 15.3C16.9999 15.5 16.8 15.5 16.6 15.6C16.4 15.6 16.3 15.7 16.2 15.8C16 15.8 16 16 16 16.1C16 16.2 16 16.3 15.9 16.4C15.8 16.5 15.6 16.5 15.4 16.5C15.1 16.5 14.7 16.5 14.3 16.7C14.1 16.8 13.8999 16.9 13.6999 17C13.2999 17.3 12.9 17.5 12.1 17.5C11.3 17.5 10.8999 17.2 10.4999 17C10.2999 16.9 10.0999 16.7 9.89995 16.7C9.49995 16.6 9.09995 16.5 8.79995 16.5C8.59995 16.5 8.39995 16.5 8.29995 16.4C7.99995 16.3 7.99995 16.2 7.99995 16.1C7.99995 16 7.99995 15.8 7.79995 15.8C7.69995 15.7 7.49995 15.7 7.39995 15.6C7.19995 15.5 6.99995 15.5 6.69995 15.3C6.39995 15.1 6.59995 15 6.69995 14.9C8.59995 14 8.89995 12.5 8.89995 12.4C8.89995 12.3 8.89995 12.2 8.79995 12.1C8.69995 12 8.39995 11.8 8.09995 11.7C7.99995 11.6 7.89995 11.6 7.79995 11.5C7.49995 11.3 7.39995 11.1 7.49995 10.9C7.59995 10.7 7.69995 10.7 7.89995 10.7C7.99995 10.7 7.99995 10.7 8.09995 10.7C8.29995 10.7 8.49995 10.8 8.59995 10.9C8.69995 10.9 8.79995 11 8.89995 11C8.89995 11 8.89995 11 8.99995 11C9.09995 11 9.09995 11 9.09995 10.8V10.7C9.09995 10.4 8.99995 9.69995 9.09995 9.19995C9.19995 8.39995 9.39995 7.89995 9.79995 7.59995C9.99995 7.39995 10.7 6.69995 12.1 6.69995C13.5 6.69995 14.1999 7.49995 14.3999 7.59995C14.6999 7.99995 15 8.39995 15.1 9.19995C15.2 9.79995 15.1 10.4 15.1 10.8C15.1 10.9 15.1 11 15.2 11C15.2 11 15.2 11 15.3 11C15.4 11 15.5 10.9 15.6 10.9C15.8 10.8 15.9 10.8 16.1 10.7C16.1 10.7 16.1999 10.7 16.2999 10.7C16.4999 10.7 16.6 10.8 16.7 10.9C16.8 11.1 16.7 11.3 16.4 11.5C16.3 11.5 16.2 11.6 16.1 11.7C15.9 11.9 15.5 12.1 15.4 12.1C15.3 12.2 15.3 12.3 15.3 12.4C15.3 12.5 15.6 13.9 17.5 14.9C17.5 15 17.6999 15.1 17.2999 15.3Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
      </svg>,
    color: "text-primary-500 bg-grey-fields-100",
    prefix: "https://www.snapchat.com/add",
  },
};
